/* eslint-disable import/extensions */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import { ModeContextProvider } from './Context/Mode/ModeContext';
import './i18n/i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LanguageContextProvider } from './Context/LanguageContext';
import { TeamContextProvider } from './Context/Data/TeamContext';
import { CareersContextProvider } from './Context/Data/CareersContext';
import { FaqContextProvider } from './Context/Data/FaqContext';
import { FooterContextProvider } from './Context/Data/FooterContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
        <ModeContextProvider>
          <LanguageContextProvider>
            <TeamContextProvider>
              <CareersContextProvider>
                <FaqContextProvider>
                  <FooterContextProvider>
                    <App />
                  </FooterContextProvider>
                </FaqContextProvider>
              </CareersContextProvider>
            </TeamContextProvider>
          </LanguageContextProvider>
        </ModeContextProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

reportWebVitals();
