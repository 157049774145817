/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import React, { createContext, useReducer } from 'react';
import { languageReducer } from '../Reducer/languageReducer';

export const LanguageContext = createContext();

export function LanguageContextProvider({ children }) {
  const [language, dispatch] = useReducer(
    languageReducer,
    [
      {
        en: {
          id: 1,
          nativeName: 'English',
        },
        tr: {
          id: 2,
          nativeName: 'Türkçe',
        },
      },
    ],
  );

  return (
    <LanguageContext.Provider value={{ language, dispatch }}>
      {children}
    </LanguageContext.Provider>
  );
}
