/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import React, { createContext, useEffect, useState } from 'react';
import {
  collection,
  getDocs,
  query,
} from 'firebase/firestore';
import db from '../../firebase';

export const FaqContext = createContext();

export function FaqContextProvider({ children }) {
  const usersCollectionRef = collection(db, 'faq');
  const [faqList, setFaqCardList] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(query(usersCollectionRef));
      setFaqCardList(data.docs.map((doc) => ({ ...doc.data() })));
    };

    getUsers();
  }, []);

  return (
    <FaqContext.Provider value={{ faqList, setFaqCardList }}>
      {children}
    </FaqContext.Provider>
  );
}
