/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/react-in-jsx-scope */
import React, { Suspense, useContext, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import './assets/styles/base/_reset.scss';
import { ModeContext } from './Context/Mode/ModeContext';
import Loader from './Component/Loader';
import firebaseAl from './firebase-analystic';

const Home = React.lazy(() => import('./Pages/Home/Home'));
const Tech = React.lazy(() => import('./Pages/HowitWorks/HowitWorks'));
const Faq = React.lazy(() => import('./Pages/Faq/Faq'));
const AboutUs = React.lazy(() => import('./Pages/AboutUs/AboutUs'));
const Careers = React.lazy(() => import('./Pages/Careers/Careers'));

logEvent(firebaseAl, 'login');
function App() {
  const { theme } = useContext(ModeContext);

  useEffect(() => {
    const clearTheme = theme === 'dark' ? 'light' : 'dark';
    document.body.classList.remove(clearTheme);
    document.body.classList.add(theme);
  }, [theme]);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tech" element={<Tech />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
}

export default App;
