/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import React, { createContext, useEffect, useState } from 'react';
import {
  collection,
  getDocs,
  query,
  orderBy,
} from 'firebase/firestore';
import db from '../../firebase';

export const CareersContext = createContext();

export function CareersContextProvider({ children }) {
  const usersCollectionRef = collection(db, 'careers');
  const [careersCardList, setCareersCardList] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(query(usersCollectionRef, orderBy('id')));
      setCareersCardList(data.docs.map((doc) => ({ ...doc.data() })));
    };

    getUsers();
  }, []);

  return (
    <CareersContext.Provider value={{ careersCardList, setCareersCardList }}>
      {children}
    </CareersContext.Provider>
  );
}
