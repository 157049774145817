/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import React, { createContext, useEffect, useState } from 'react';
import {
  collection,
  getDocs,
  query,
  orderBy,
} from 'firebase/firestore';
import db from '../../firebase';

export const TeamContext = createContext();

export function TeamContextProvider({ children }) {
  const usersCollectionRef = collection(db, 'team');
  const [teamCardList, setTeamCardList] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(query(usersCollectionRef, orderBy('id')));
      setTeamCardList(data.docs.map((doc) => ({ ...doc.data() })));
    };

    getUsers();
  }, []);

  return (
    <TeamContext.Provider value={{ teamCardList, setTeamCardList }}>
      {children}
    </TeamContext.Provider>
  );
}
