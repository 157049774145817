/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import React, { createContext, useEffect, useState } from 'react';

export const ModeContext = createContext({ theme: 'light', undefined });

export function ModeContextProvider({ children }) {
  const [theme, setTheme] = useState('dark');

  useEffect(() => {
    const initialTheme = window.localStorage.getItem('theme');
    if (initialTheme === 'dark' || initialTheme === 'light') {
      setTheme(initialTheme);
    } else {
      window.localStorage.setItem('theme', theme);
      setTheme('dark');
    }
  }, []);

  const setModeTheme = (event) => {
    event.preventDefault();
    const newThemeMode = theme === 'light' ? 'dark' : 'light';
    window.localStorage.setItem('theme', newThemeMode);
    setTheme(newThemeMode);
  };

  return (
    <ModeContext.Provider value={{ theme, setModeTheme }}>
      {children}
    </ModeContext.Provider>
  );
}
