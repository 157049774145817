import React from 'react';

export default function Loader() {
  return (
    <div style={{
      display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', overflow: 'hidden',
    }}
    >
      <object
        className="mx-auto"
        aria-label="Loader"
        data="assets/loader.svg"
        type="image/svg+xml"
        style={{
          height: '25%', width: '25%',
        }}
      />
    </div>
  );
}
