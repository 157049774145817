/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import React, { createContext, useEffect, useState } from 'react';
import {
  collection,
  getDocs,
  query,
} from 'firebase/firestore';
import db from '../../firebase';

export const FooterContext = createContext();

export function FooterContextProvider({ children }) {
  const usersCollectionRef = collection(db, 'footer');
  const [footerData, setFooterList] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(query(usersCollectionRef));
      setFooterList(data.docs.map((doc) => ({ ...doc.data() })));
    };

    getUsers();
  }, []);

  return (
    <FooterContext.Provider value={{ footerData, setFooterList }}>
      {children}
    </FooterContext.Provider>
  );
}
